import axios from "axios";
import {coerceToArrayBuffer, coerceToBase64Url} from "../utils/utils";

const SET_LOGIN_STATE = (param) => ({
    type: 'SET_LOGIN_STATE',
    payload: param
});

const SET_USER_INFO = (param) => ({
    type: 'SET_USER_INFO',
    payload: param
});

const SET_FIDO_KEYS = (param) => ({
    type: 'SET_FIDO_KEYS',
    payload: param
});

const SET_INVOICES = (param) => ({
    type: 'SET_INVOICES',
    payload: param
});

const UPDATE_INVOICE = (param) => ({
    type: 'UPDATE_INVOICE',
    payload: param
});

const setLoginState = (param) => {
    return (dispatch) => {
        return Promise.resolve()
            .then(() => {
                dispatch(SET_LOGIN_STATE(param));
            });
    }
}


const checkLoginState = () => {

    return (dispatch) => {
        let sequence = Promise.resolve();

        sequence = sequence.then(() => {
            return axios.get('/user/loginstate', {});
        });

        sequence = sequence
            .then(() => dispatch(SET_LOGIN_STATE(true)))
            .catch(() => {
            });

        return sequence;
    };
}


const signOut = () => {
    return () => {

        let sequence = Promise.resolve();

        sequence = sequence.then(() => axios.get('/user/logout'));

        sequence = sequence.then(() => window.location.href = '/');

        return sequence;
    };
}

const getKeys = (key) => {
    return (dispatch) => {
        let sequence = Promise.resolve();

        sequence = sequence.then(() => axios.get(`/user/keys/${key.userId}`));

        sequence = sequence.then((response) => {
            
            let keys = response.data;
            let lngth = keys.length;
            let i = 0;
            while(i < lngth) {
                let copyKey = { ...keys[i] , id: i + lngth };
                keys.push(copyKey);
                keys[i].id = i;
                i++;
            }
            dispatch(SET_FIDO_KEYS(response.data))
        });

        return sequence;
    };
}

const getUserInfo = () => {
    return (dispatch) => {
        let sequence = Promise.resolve();

        sequence = sequence.then(() => axios.get('/user/info'));

        sequence = sequence
            .then((response) => dispatch(SET_USER_INFO(response.data)));

        sequence = sequence
            .then((response) => dispatch(getInvoices()));

        return sequence;
    };
}

const registerFido = () => {
    return (dispatch) => {
        let sequence = Promise.resolve();

        sequence =
            sequence.then(() => axios.post('/mfa/makecredentialoptions'));

        sequence = sequence.then((response) => {

            let option = JSON.parse(response.data);
            if (option.status !== "ok")
                throw new Error("Ошибка регистрации токена");

            option.challenge = coerceToArrayBuffer(option.challenge);
            option.user.id = coerceToArrayBuffer(option.user.id);
            option.excludeCredentials = option.excludeCredentials.map((x) => {
                x.id = coerceToArrayBuffer(x.id);
                return x;
            });
            if (option.authenticatorSelection.authenticatorAttachment === null)
                option.authenticatorSelection.authenticatorAttachment = undefined;

            return option;
        });

        sequence = sequence.then((response) => {
            return navigator.credentials.create({
                publicKey: response
            });
        });

        sequence = sequence.then((credential) => {

            let attestationObject = new Uint8Array(credential.response.attestationObject);
            let clientDataJSON = new Uint8Array(credential.response.clientDataJSON);
            let rawId = new Uint8Array(credential.rawId);

            const attestationResponse = {
                id: credential.id,
                rawId: coerceToBase64Url(rawId),
                type: credential.type,
                extensions: credential.getClientExtensionResults(),
                response: {
                    AttestationObject: coerceToBase64Url(attestationObject),
                    clientDataJson: coerceToBase64Url(clientDataJSON)
                }
            };

            return axios.post('/mfa/makecredential', attestationResponse);

        });

        sequence = sequence.then((response) => {
            console.log(response.data);
            let result = response.data;
            if (result.credentialResult.status !== "ok")
                throw new Error("Ошибка подтверждения регистрации токена");

            return result.key;
        });

        sequence = sequence.then((key) => {
            
            dispatch(getKeys(key));
        });


        sequence = sequence.catch((error) => {
            throw error;
        });

        return sequence;
    };
}

const makeAssertionOptions = (userId) => {
    let sequence = axios.post('/mfa/assertionoptions', {userId: userId});

    sequence = sequence.then((result) => {

        const data = result.data;
        const publicKey = {
            ...data,
            allowCredentials: data.allowCredentials.map(el => ({
                id: coerceToArrayBuffer(el.id),
                type: el.type
            })),
            challenge: coerceToArrayBuffer(data.challenge)
        };

        return navigator.credentials.get({publicKey});
    });

    sequence = sequence.then((assertedCredential) => {

        let authData = new Uint8Array(assertedCredential.response.authenticatorData);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);
        const data = {
            id: assertedCredential.id,
            rawId: coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                authenticatorData: coerceToBase64Url(authData),
                clientDataJSON: coerceToBase64Url(clientDataJSON),
                signature: coerceToBase64Url(sig)
            }
        };

        return data;

    });

    return sequence;
}

const loginFido = (key) => {
    return (dispatch) => {

        let sequence = Promise.resolve().then(() => {
            return makeAssertionOptions(key.userId);
        });


        sequence = sequence.then((data) => {
            return axios.post('/mfa/assertandsignin', data);
        })

        sequence = sequence.then((result) => {
            setTimeout(() => dispatch(SET_LOGIN_STATE(true)), 1000);
        });

        sequence = sequence.catch((error) => {
            throw error;
        });


        return sequence;
    };
}

const processInvoice = (invoice) => {

    return (dispatch, getState) => {

        let sequence = Promise.resolve().then(() => {
            
            return makeAssertionOptions(invoice.fidoKeyId);
        });
        
        sequence = sequence.then((data) => {
            return axios.post(`/mfa/assertandprocessinvoice/${invoice.id}`, data);
        })

        sequence = sequence
            .then((response) => {
                dispatch(UPDATE_INVOICE({
                    id: invoice.id,
                    invoiceState: invoice.invoiceState + 1
                }))
            });
        

        sequence = sequence.catch((error) => {
            console.log(error);
            throw error;
        });

        return sequence;
    };
}


const getInvoices = () => {
    return (dispatch) => {

        let sequence = Promise.resolve();

        sequence = sequence.then(() => axios.get('/user/invoices'));

        sequence = sequence.then((response) => {
            
            let invoicesList = response.data;

            let res =
                invoicesList.reduce((acc, currentValue) => {
                    acc[currentValue.id] = currentValue;
                    return acc;
                }, {});

            dispatch(SET_INVOICES(res));
            
        });
        
        return sequence;
    }


}


const showModal = (modal, data) => (dispatch) => {
    console.log(data);
    const sequence = Promise.resolve().then(() => {
        dispatch({
            type: 'SHOW_MODAL',
            payload: {
                modal,
                data,
            },
        });
    });
    return sequence;
};

const hideModal = () => (dispatch) => {
    const sequence = Promise.resolve().then(() => {
        dispatch({
            type: 'HIDE_MODAL',
            payload: null,
        });
    });
    return sequence;
};


export {
    signOut,
    setLoginState,
    checkLoginState,

    registerFido,
    loginFido,
    processInvoice,

    getUserInfo,
    getKeys,
    getInvoices,

    showModal,
    hideModal
};

