import React from "react";
import {useDispatch, useSelector} from 'react-redux';

import {showModal} from "../actions";
import ProcessInvoiceModal from "./ProcessInvoiceModal";
import {INVOICE_ACTIONS, INVOICE_STATE} from "./InvoiceState";



const RenderInvoiceStatus = ({invoice}) => {
    return INVOICE_ACTIONS[invoice.invoiceState];
}


const InvoiceRow = ({invoice}) => {
 
    const dispatch = useDispatch();

    const encryptAndSend = () => {
  
        dispatch(showModal(ProcessInvoiceModal, invoice));
    }


    return (
        <div className="item-device d-flex align-items-center justify-content-between cursor-pointer"
             onClick={() => encryptAndSend()}>
            <div>
                <div className="d-flex align-items-center column-gap-4">
                    <div className="fw-bolder">Платежное поручение # {invoice.id}</div>
                </div>
            </div>
            <div>
                <RenderInvoiceStatus invoice={invoice} />
            </div>
        </div>
    );
}


const RenderInvoiceList = () => {

    const invoices = useSelector(state => state.invoices);
    if (!invoices) return <></>;
    console.log(invoices);
    return (
        <div className="pb-4">
            <div>
                {Object.keys(invoices).map((key) => 
                    <InvoiceRow key={key} invoice={invoices[key]}></InvoiceRow>)}
            </div>
        </div>

    )
}


export default RenderInvoiceList;
