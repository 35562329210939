import {INVOICE_STATE, INVOICE_ACTIONS} from "./InvoiceState";
import React from "react";

const RenderInitInvoiceState = ({invoice, submit, close}) => {
    if(invoice.invoiceState === INVOICE_STATE.decrypted) {
        return (
            <div className=''>
                <div className='d-flex flex-column justify-content-between'>
                    <div className='d-flex flex-row justify-content-between'>
                        <strong>Назначение платежа:</strong>
                        <span>Перевод</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <strong>Сумма:</strong>
                        <span>100 000</span>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-stretch mt-4'>
                    <button className='add-mfa' onClick={() => submit()}>
                        {INVOICE_ACTIONS[invoice.invoiceState]}
                    </button>
                </div>
                <div className="text-center d-block mt-2">
                        <span className="
                            register-toggle-link
                            fw-bolder
                            cursor-pointer"
                              onClick={() => close()}
                        >
                            Назад
                        </span>
                </div>
            </div>
        )
    }

    if(invoice.invoiceState === INVOICE_STATE.signed) {
        return (
            <div className=''>
                <div className='d-flex flex-column justify-content-between'>
                    <div className='d-flex flex-row justify-content-between'>
                        <strong>Назначение платежа:</strong>
                        <span>Перевод</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <strong>Сумма:</strong>
                        <span>100 000</span>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-stretch mt-4'>
                    <button className='add-mfa' onClick={() => submit()}>
                        {INVOICE_ACTIONS[invoice.invoiceState]}
                    </button>
                </div>
                <div className="text-center d-block mt-2">
                        <span className="
                            register-toggle-link
                            fw-bolder
                            cursor-pointer"
                              onClick={() => close()}
                        >
                            Назад
                        </span>
                </div>
            </div>
        )
    }

    if(invoice.invoiceState === INVOICE_STATE.encryptedAndSent) {
        return (
            <div className='modal-pb d-flex flex-column align-items-center'>
                <div className='d-flex flex-column align-items-center'>
                    Платежное поручение отправлено
                    <br />
                </div>
                <div className="text-center d-block mt-2">
                        <span className="
                            register-toggle-link
                            fw-bolder
                            cursor-pointer"
                              onClick={() => close()}
                        >
                            Назад
                        </span>
                </div>
            </div>
        )
    }

    return <div />;
}

export default RenderInitInvoiceState;