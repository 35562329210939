const INVOICE_STATE = {
    encrypted: 0,
    decrypted: 1,
    signed: 2,
    encryptedAndSent: 3
};

const INVOICE_ACTIONS = {
    [INVOICE_STATE.encrypted]: 'Расшифровать',
    [INVOICE_STATE.decrypted]: 'Подписать',
    [INVOICE_STATE.signed]: 'Зашифровать и отправить',
    [INVOICE_STATE.encryptedAndSent]: 'Отправлено'
}


export {INVOICE_ACTIONS, INVOICE_STATE};