import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {getUserInfo, signOut} from "../actions";

import Layout from "../common/Layout";
import RenderInvoiceList from "./RenderInvoiceList";


const Personal = () => {
    const userInfo = useSelector(state => state.userInfo);
    const [showCred, setShowCred] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserInfo());
    }, []);

    if (!userInfo) return <></>;

    return (
        <Layout>
            <div className="personal">
                <div className="personal-heading">Личный кабинет</div>
                <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="personal-info cursor-pointer"
                             onClick={() => setShowCred(!showCred)}>
                            <div className="personal-info__logo"></div>
                            <div className="personal-info__name">{userInfo.userName}</div>
                        </div>
                        <div className="personal-logout" onClick={() => dispatch(signOut())}>
                            <div className="personal-logout__text">Выйти</div>
                            <div className="personal-logout__logo"></div>
                        </div>
                    </div>

                    {
                        showCred && (
                            <>
                                <div className="personal-expiration">
                                    <div className="personal-expiration__text">CredentId:</div>
                                    <div className="personal-expiration__value">{userInfo.credentialId}</div>
                                </div>
                                <div className="personal-expiration">
                                    <div className="personal-expiration__text">PublicKey:</div>
                                    <div className="personal-expiration__value">{userInfo.publicKey}</div>
                                </div>
                            </>
                        )
                    }
                    <div className="pb-4">
                        <div className="personal-two-factor-heading">Платежные поручения</div>
                        <RenderInvoiceList />
                    </div>


                </div>
            </div>
        </Layout>
    );
}

export default Personal;
