import React, {useEffect} from "react";
import {getKeys, showModal} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import RegisterFidoModal from "./RegisterFidoModal";
import LoginFidoModal from "./LoginFidoModal";


const CertificateCard = ({fidoKey}) => {
    const dispatch = useDispatch();

    const loginFidoDevice = () => {
        dispatch(showModal(LoginFidoModal, fidoKey));
    }
    return (
        <div
            className="certificatelist_certificate"
            role="button"
            tabIndex={0}
            onClick={() => loginFidoDevice()}
        >
            <div className="certificate_body">
                <div className="certificate_icon"/>
                <div className="certificate_info">
                    <div className='fw-bolder'>{fidoKey.userName}</div>
                    <span className='d-block mt-4'>{`Кошелек ${fidoKey.id + 1}`}</span>
                </div>

            </div>
        </div>
    );
};

const CertificatesContainer = () => {
    const dispatch = useDispatch();

    const keys = useSelector(state => state.fidoKeys);

    const registerFidoDevice = () => {
        dispatch(showModal(RegisterFidoModal));
    }

    return (
        <div className="register-form-container">
            <h4 className="text-center mb-4">
                Войти в аккаунт платформы цифрового рубля
            </h4>
            {
                keys.length > 0 && (
                    keys.map(key => <CertificateCard key={key.id} fidoKey={key}/>)
                )
            }

            <div className="text-center d-block">
                        <span className="
                            register-toggle-link
                            fw-bolder
                            cursor-pointer"
                              onClick={() => registerFidoDevice()}
                        >
                            {
                                keys.length === 0 ? "Войти" : "Войти с другим устройством"
                            }
                        </span>
            </div>
        </div>
    );
}


const Auth = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getKeys())
    }, []);


    return (
        <div className="register-background">
            <div className="layout-container layout-container--auth">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className='layout-logo layout-logo--auth'></div>
                        </div>
                        <div className="col-lg-8">
                            <CertificatesContainer></CertificatesContainer>
                        </div>

                        <div className="col-lg-2">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}


export default Auth;